
import { defineComponent, onMounted } from 'vue'
import {} from '@/shims-google-auth'

export default defineComponent({
	name: 'LoginButton',
	setup() {
		onMounted(() => {
			window.google.accounts.id.renderButton(document.getElementById('google-signin'), {
				theme: 'outline',
				size: 'large',
			})
		})
	},
})
