<template>
	<div class="login">
		<login-button></login-button>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoginButton from '@/components/Auth/LoginButton.vue'

export default defineComponent({
	name: 'Home',
	layout: 'Default',
	components: {
		[LoginButton.name]: LoginButton,
	},
})
</script>

<style lang="scss" scoped>
.login {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
