
import { defineComponent } from 'vue'
import LoginButton from '@/components/Auth/LoginButton.vue'

export default defineComponent({
	name: 'Home',
	layout: 'Default',
	components: {
		[LoginButton.name]: LoginButton,
	},
})
